import { AuthorInfos, AuthorInfosProps } from '@pretto/zen/seo/blocAuthor/AuthorInfos/AuthorInfos'
import { SharingLinks, SharingLinksProps } from '@pretto/zen/seo/blocAuthor/SharingLinks/SharingLinks'

import * as S from './BlocAuthorEnd.styles'

interface AuthorProps extends AuthorInfosProps {
  biography: string
}

export interface BlocAuthorEndProps {
  authorProps: AuthorProps
  lastUpdate: string
  sharingProps: SharingLinksProps
}

export const BlocAuthorEnd = ({ authorProps, lastUpdate, sharingProps, ...props }: BlocAuthorEndProps) => (
  <S.BlocAuthorEnd {...props}>
    <S.Update>{lastUpdate}</S.Update>
    <S.Content>
      <AuthorInfos {...authorProps} />
      <SharingLinks {...sharingProps} />
    </S.Content>
    <S.Text>{authorProps.biography}</S.Text>
  </S.BlocAuthorEnd>
)
