import { g } from '@pretto/bricks/components/layout'
import * as typo from '@pretto/bricks/core/typography'
import styled from 'styled-components'

export const BlocAuthorEnd = styled.div``

export const Update = styled.div`
  ${typo.caption12};
  color: ${({ theme }) => theme.colors.neutral2};
`
export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: ${g(1)};
`
export const Text = styled.div`
  ${typo.bodyBook16};
  color: ${({ theme }) => theme.colors.neutral2};
  margin-top: ${g(2)};
`
